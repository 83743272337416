import axios from 'axios';

import { generateStateToken } from './utils/helpers';

// Sidebar
import CompanyappSidebar from './partials/companyapp/sidebar';
import RHGSidebar from './partials/rhg/sidebar';
import EquansSidebar from './partials/equans/sidebar';

// LOGO
import rhgLogo from "./assets/rhg-logo.svg";
import companyappLogo from "./assets/ca-logo-pwa.png";
import equansLogo from "./assets/equans-logo.svg";

axios.defaults.withCredentials = true;

export const pageTitle = process.env.REACT_APP_PAGE_TITLE ? process.env.REACT_APP_PAGE_TITLE : 'Companyapp'
export const serverUrl = process.env.REACT_APP_CMS;
export const filesystem_root = process.env.REACT_APP_FILES;
export const subdomain = process.env.REACT_APP_SUBDOMAIN;
export const proxy = process.env.REACT_APP_FRAME_PROXY;
export const publishing_sse = process.env.REACT_APP_PUBLISHING_SSE;
export const globalThemeDirectory = `${filesystem_root}/${subdomain}/themes/presenter_global_theme`;
export const globalThemeStylesheet = `${globalThemeDirectory}/dist/css/main.css`;
export const root_auth_handshake = process.env.REACT_APP_ROOT_AUTH_HANDSHAKE;
export const client = process.env.REACT_APP_CLIENT;
export const isSso = client === 'companyapp' ? false : true;
export const favoritesEnabled = process.env.REACT_APP_FAVORITES_ENABLED === 'true' ? true : false;
export const permissionsEnabled = process.env.REACT_APP_PRESENTER_PERMISSIONS === 'true' ? true : false;
export const themesEnabled = process.env.REACT_APP_PRESENTER_THEMES === 'true' ? true : false;
export const appConfigEnabled = process.env.REACT_APP_CONFIG === 'true' ? true : false;
export const notificationsEnabled = process.env.REACT_APP_NOTIFICATIONS === 'true' ? true : false;
export const presenterTaxonomies = process.env.REACT_APP_PRESENTER_TAXONOMIES === 'true' ? true : false;
export const advancedHeaderMenu = process.env.REACT_APP_ADVANCED_HEADER_MENU === 'true' ? true : false;
export const searchEnabled = process.env.REACT_APP_SEARCH_ENABLED === 'true' ? true : false;
export const editProfileEnabled = process.env.REACT_APP_EDIT_PROFILE === 'true' ? true : false;
export const mediaLibraryEnabled = process.env.REACT_APP_MEDIA_LIBRARY === 'true' ? true : false;
export const presenterNotesEnabled = process.env.REACT_APP_PRESENTER_NOTES_ENABLED === 'true' ? true : false;
export const presenterAudioEnabled = process.env.REACT_APP_PRESENTER_AUDIO_ENABLED === 'true' ? true : false;
export const deepLinks = [
  'caequans://',
  'cacompanyapp://',
  'communicator://'
];

export const AppLogo = () => {
  if (client !== 'companyapp') {
    if (client === 'radisson') {
      return <img src={rhgLogo} alt='radisson hotel group logo' />
    }

    if (client === 'equans') {
      return <img src={equansLogo} alt='EQUANS logo' />
    }
  } else {
    return <img src={companyappLogo} alt='companyapp logo' />
  }
};

export const Sidebar = ({showNavigation, isMobile, sidebarVisibility}) => {
  if (client !== 'companyapp') {
    if (client === 'radisson') {
      return <RHGSidebar showNavigation={showNavigation} isMobile={isMobile} sidebarVisibility={sidebarVisibility} />
    }

    if (client === 'equans') {
      return <EquansSidebar showNavigation={showNavigation} isMobile={isMobile} sidebarVisibility={sidebarVisibility} />
    }
  } else {
    return <CompanyappSidebar showNavigation={showNavigation} isMobile={isMobile} sidebarVisibility={sidebarVisibility} />
  }
};

export const oAuthConfig = () => {
  return {
    client_id: process.env.REACT_APP_CLIENT_ID,
    url: `${serverUrl}/oauth/authorize`,
    redirect_uri: `${window.location.origin}/login/callback`,
    state: generateStateToken(),
    scope:
      "openid email profile authenticated subeditor editor administrator",
  };
};

const endpoints = () => {
  let resources = {
    categories: 'api/v2/taxonomies/',
    contacts: 'custom_user',
    documents: 'api/v2/documents',
    documentCategories: 'api/v2/taxonomies/document_categories',
    events: 'api/v2/events',
    forms: 'api/v2/webforms',
    links: 'api/v2/links',
    linksCategories: 'api/v2/taxonomies/link_categories',
    news: 'api/v2/news',
    newsCategories: 'api/v2/taxonomies/news',
    podcasts: 'api/v2/podcasts',
    polls: 'api/v2/polls',
    quiz: 'api/quiz',
    training: 'api/training',
    videos: 'api/v2/videos',
    reactions: 'api/v2/reactions',
    search: 'api/v2/search',
    contribute: 'content_submission'
  };

  switch (client) {
    case "radisson":
      resources = { ...endpoints };
      break;
    case "companyapp":
      // resources = { ...endpoints };
      break;
    case "equans":
      resources = {
        ...resources,
        documentCategories: 'api/v2/taxonomies/document_ca',
        linksCategories: 'api/v2/taxonomies/external_resource_categories',
        newsCategories: 'api/v2/taxonomies/news_categories',
      };
      break;
    default:
      resources = { ...endpoints };
      break;
  }

  return resources;
};

export const apiEndpoints = endpoints();

export const api = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
  timeout: 3000000, // 30s
  headers: {
    'Content-Type': 'application/json',
  },
});
