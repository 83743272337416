// Dependencies
import React from "react";

// Modules
import { connect } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl, filesystem_root, subdomain } from "../../config";
import { getOnlineNode } from "../../core/getNode";
import PostTitle from "../../partials/postTitle";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";
import { formatTime } from "../../utils/helpers";
import UserImage from "../../partials/userImage";
import PostBody from "../../partials/postBody";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowBack } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { Chip, Link, Typography, IconButton } from "@mui/material";
import TimeIcon from "@mui/icons-material/AccessTimeOutlined";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import CallIcon from "@mui/icons-material/CallOutlined";
import SinglesHeader from "../../partials/singlesHeader";

import ClientTheme from "../../core/clientTheme";

// Dummy data
export default function CompanyDirectorySingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [post, setPost] = useState(null);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total_pages, setTotal_Pages] = useState(0);
  const [isReactionsLoading, setIsReactionsLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(null);
  const [reactions, setReactions] = useState({
    hasReacted: false,
    likes: 0,
    love: 0,
    suprised: 0,
  });

  const clientTheme = { ...ClientTheme() };

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  useEffect(() => {
    loadNode();
  }, []);
  useEffect(() => {
    if (post) {
      getCategory();
    }
  }, [post]);

  const dummyData = require("./dummyData.json");

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsLoading(false);
    setIsError(false);
    // getComments();
    // getReactions();
    getCategory(_data.field_category);
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = "custom_user/" + "262";

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          // setData(response.data.rows[0]);
          let item = dummyData.find((e) => e.nid == params.id);
          setData(item);
          console.log("item", item);
        } else {
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        // if (_error.response) {
        //   setError(
        //     true,
        //     _error.response.status,
        //     _error.response.statusText,
        //   );
        // }
        // else if (_error.request) {
        //   setError(true, 0, alertMessages.requestError.message);
        //   console.log('this got triggered 1');
        // } else {
        //   setError(true, 0, alertMessages.unkownError.message);
        //   console.log('this got triggered 2');
        // }
      });
  };

  const getCategory = (id) => {
    if (post.field_category) {
      getOnlineNode(
        `api/categories?tid=${post.field_category}`,
        user.access_token
      )
        .then((response) => {
          let categoryTemp =
            response.data.length > 0 ? response.data[0] : { title: "" };
          setCategoryName(categoryTemp.title);
        })
        .catch((_error) => {
          if (_error.response) {
            setError(true, _error.response.status, _error.response.statusText);
          } else if (_error.request) {
            //console.log('@rest request: ', _error.request);
            setError(true, 0, alertMessages.requestError.message);
          } else {
            //console.log('@rest unknown: ', _error);
            setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  };

  const cleanString = (inputString) => {
    const cleanedString = inputString.replace(/&\s/g, "");

    return cleanedString;
  };

  const infoProperty = (propertyString) => {
    return (
      <Col xs={3}>
        <Typography
          owner="client"
          variant="body2"
          color="clientText.main"
          lh="wide"
          weight="bold"
          singles="true"
        >
          {propertyString}:
        </Typography>
      </Col>
    );
  };

  const infoValue = (valueString) => {
    return (
      <Col xs={8} className="flex-grow-1">
        <Typography
          owner="client"
          variant="body2"
          color="clientText.main"
          lh="wide"
          weight="light"
          singles="true"
        >
          {valueString}
        </Typography>
      </Col>
    );
  };

  const contactGroup = (groupItem, index) => {
    return (
      <Row
        id={`group-item-${index}`}
        className="group-item-container pt-2 pb-3 mt-3"
      >
        <Col>
          <Row className="contact-group-name mb-2">
            <Col xs={12}>
              <Typography
                owner="client"
                className="pt-2 mb-3"
                variant="h5"
                color="clientText.main"
                singles="true"
              >
                {groupItem.field_section_title}
              </Typography>
            </Col>
          </Row>
          {groupItem.field_phone_number &&
            groupItem.field_phone_number !== "" && (
              <Row className="contact-group-phone mb-1">
                <Col xs={12}>
                  <Row className="gx-0 display-flex align-items-center">
                    <Col xs="auto" className="pe-2">
                      <CallIcon size="small" color="clientPrimary" />
                    </Col>
                    <Col>
                      <Typography
                        className="group-item-phone"
                        variant="body2"
                        color="client"
                      >
                        <Link
                          owner="client"
                          variant="body2"
                          singles="true"
                          weight="regular"
                          lh="wide"
                          hoverbehavior={clientTheme.components.link.hoverbehavior}
                          href={`tel:${groupItem.field_phone_number}`}
                          target='_blank'
                          rel="noreferrer">
                          {groupItem.field_phone_number}
                        </Link>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          {groupItem.field_email && groupItem.field_email !== "" && (
            <Row className="contact-group-mail mb-1">
              <Col xs={12}>
                <Row className="gx-0 display-flex align-items-center">
                  <Col xs="auto" className="pe-2">
                    <EmailIcon size="small" color="clientPrimary" />
                  </Col>
                  <Col>
                    <Typography
                      className="group-item-email"
                      variant="body2"
                      color="clientText.main"
                    >
                      <Link
                          owner="client"
                          variant="body2"
                          singles="true"
                          weight="regular"
                          lh="wide"
                          hoverbehavior={clientTheme.components.link.hoverbehavior}
                          href={`mailto:${groupItem.field_email}`}
                          target='_blank'
                          rel="noreferrer">
                          {groupItem.field_email}
                        </Link>
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      const timestamp = formatTime(post.created);

      let category = "Uncategorised";

      if (categoryName) {
        category = categoryName;
      }

      if (post.category_labels) {
        category = post.category_labels;
      }

      return (
        <main className="company-directory  single">
          <article className="article">
            <SinglesHeader
              route={"/company_directory"}
              backBtnText={"Companyapp Directory"}
              category={category}
              timestamp={post.created}
            />
            <div className="company-directory-page-body">
              <Row className="information-heading-container justify-content-center mb-4">
                <Col xs={4} sm={2} className="avatar-container mb-4 mb-sm-0">
                  <UserImage
                    className="ratio ratio-1x1"
                    name={cleanString(post.title)}
                    image={post.user_picture}
                  />
                </Col>
                <Col xs={8} sm={10} className="contact-info-container ps-3">
                  <Row className="mb-3">
                    <Col>
                      <PostTitle
                        title={post.title}
                        headingLevel={"h1"}
                        titleColor="clientText.dark"
                      />
                      <div className="sub-title position mt-2 mb-3">
                        <Typography
                          variant="h4"
                          owner="client"
                          color="clientPrimary.main"
                          weight="light"
                        >
                          {post.category}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="company-directory-information-body-container mb-4">
                <Col xs={12}>
                  <Row className="company-directory-body mb-3">
                    <Col xs={12}>
                      <div className="article__body">
                        <PostBody body={post.body} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="company-directory-hours gx-0 d-flex align-items-center mb-3">
                    {infoProperty("Office hours")}
                    <Col xs={"auto"} className="px-1">
                      <TimeIcon size="small" color="clientPrimary" />
                    </Col>
                    {infoValue(
                      `${post.field_start_time} - ${post.field_end_time}`
                    )}
                  </Row>
                  <Row className="company-directory-location gx-0 d-flex align-content-center align-items-center mb-3">
                    {infoProperty("Location")}
                    <Col xs={"auto"} className="px-1">
                      <LocationIcon size="small" color="clientPrimary" />
                    </Col>
                    {infoValue(`${post.field_address}`)}
                  </Row>
                </Col>
              </Row>
              {post.contactGroups.length > 0 && (
                <>
                  {post.contactGroups.map((e, i) => {
                    return contactGroup(e, i);
                  })}
                </>
              )}
            </div>
          </article>
        </main>
      );
    }
  }
}
