import HelpJson from './help.json'

export default function HelpText(layout, hasImage, contentPosition, section) {
  const helpObj = HelpJson
  const layoutObj = helpObj?.layouts.find(el => el.name === layout);
  let category = false
  let variant = false

  switch (layout) {
    case 'biography':
      break;
    case 'clients':
      break;
    case 'contact-us':
      break;
    case 'data-table':
      break;
    case 'embed':
      break;
    case 'four-column':
      category = true;
      if (hasImage) {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'four-column-icons':
      break;
    case 'fullscreen-image':
      break;
    case 'fullscreen-image-gallery':
      break;
    case 'fullscreen-video':
      break;
    case 'healthcare-meeting-types':
      break;
    case 'horizontal-three-point':
      break;
    case 'image-gallery':
      break;
    case 'header-and-image':
      break;
    case 'header-and-video':
      break;
    case 'image-with-vertical-overlay':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'image-with-horizontal-overlay':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'location-map':
      break;
    case 'one-column':
      category = true;
      if (hasImage == true) {
        variant = true;
      }
      if (hasImage == false) {
        variant = false;
      }
      break;
    case 'one-column-arch-image':
      break;
    case 'one-column-bullets':
      break;
    case 'one-column-centred-bullets':
      break;
    case 'one-column-four-images':
      break;
    case 'one-column-full-image-bullets':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'one-column-full-height-image':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'one-column-half-circle-image':
      break;
    case 'one-column-image':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'one-column-image-bullets':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'one-row-image':
      category = true;
      if (contentPosition == "left") {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'presentation-title':
      break;
    case 'presentation-title-image':
      break;
    case 'quote':
      break;
    case 'section-title':
      break;
    case 'section-title-arch-image':
      break;
    case 'small-print':
      break;
    case 'statement':
      break;
    case 'statistic':
      break;
    case 'table-of-contents':
      break;
    case 'thank-you':
      break;
    case 'three-column':
      category = true;
      if (hasImage) {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'three-column-image':
      break;
    case 'three-usp':
      break;
    case 'two-column':
      category = true;
      if (hasImage) {
        variant = true;
      } else {
        variant = false;
      }
      break;
    case 'two-column-bullets':
      break;
    case 'two-column-with-subtitle':
      break;
    case 'vertical-three-point':
      break;
  }


  if (layoutObj) {
    if (category) {
      if (variant === true) {
        if (section === 'header') {
          return layoutObj?.variants?.ex1.header
        } else {
          return layoutObj?.variants?.ex1.body
        }
      }
      else {
        if (section === 'header') {
          return layoutObj?.variants?.ex2.header
        } else {
          return layoutObj?.variants?.ex2.body
        }
      }
    }
    else {
      if (section === 'header') {
        return layoutObj.header;
      } else {
        return layoutObj.body;
      }
    }
  }
  else {
    return '';
  }
}