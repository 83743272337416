import { LinkIcon, CloseIcon, LocationFooterIcon } from '../../../icons/icons'
import BtnTooltip from '../../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class LocationFooter {

  static get toolbox() {
    return {
      icon: LocationFooterIcon,
      title: 'Location Footer',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
    };
  }

  static get DEFAULT_LOCATION_PLACEHOLDER() {
    return 'Location'
  }


  constructor({ data, config, api, readOnly, block }) {
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-hotel-footer-container',
      contentContainer: 'cdx-hotel-footer-content-container',
      wrapper: 'cdx-hotel-footer',
      input: 'cdx-hotel-footer-input',
      locationContainer: 'hotel-footer-location-container',
      location: 'hotel-footer-location',
      locationLabel: 'hotel-footer-location-label',
      hotelUrl: 'hotel-footer-url',
      modal: 'hotel-footer-url-modal',
      modalVisible: 'show',
      input: 'hotel-footer-url-input',
      modalClose: 'hotel-footer-modal-close',
      urlBtn: 'hotel-footer-url-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      clear: 'clear',
      editorFont: 'editor-font',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      editorBlockModalInput: 'editor-block-modal-input',
      editorBlockModalImgContainer: 'editor-block-modal-img-container',
      adminFont: 'admin-font',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      location: {
        value: data?.location?.value || 0,
        text: data?.location?.text || ''
      },
      hotelUrl: data.hotelUrl || '',
    };
    this._container = ''
    this._locationPlaceHolder = config.locationPlaceHolder ? config.locationPlaceHolder : LocationFooter.DEFAULT_LOCATION_PLACEHOLDER;
    // this._locationIcon = this.getLocationIcon()
    this._location = this.getLocation();
    this._hotelUrl = this.getHotelUrl();
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  getLocation() {
    let tag = document.createElement('select');
    const optionsArray = ["City Center", "Urban", "Suburban", "Small Town", "Motorway", "Airport", "Resort"];
    // Loop through the array and create an option element for each item
    tag.classList.add(this._CSS.location);
    optionsArray.forEach((e, i) => {
      const option = document.createElement("option");
      option.text = e;
      option.value = i; // You can set the value to a different value if needed

      tag.appendChild(option);
    });

    tag.selectedIndex = this._data.location.value;

    tag.onchange = () => this.handleLocationSelection();

    return tag
  }

  handleLocationSelection() {
    const selectedOption = this._location.options[this._location.selectedIndex];

    // Get the selected option's value and text

    this._data.location = {
      value: selectedOption.index,
      text: selectedOption.text,
    }

    this._location.value = selectedOption.index
    this.block.dispatchChange()
  }

  getHotelUrl() {
    let tag = document.createElement('a');
    tag.classList.add(this._CSS.hotelUrl);
    tag.innerText = 'VISIT HOTEL WEBSITE';
    tag.href = this._data.hotelUrl;
    tag.setAttribute('target', '_blank');

    return tag
  }

  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  render() {
    this._container = document.createElement('div');
    this._container.classList.add(this._CSS.container);
    let contentContainer = document.createElement('div');
    let locationContainer = document.createElement('div');
    let locationLabel = document.createElement('p');

    locationContainer.classList.add(this._CSS.locationContainer);
    locationLabel.classList.add(this._CSS.locationLabel);

    locationLabel.innerText = 'LOCATION:';
    locationContainer.appendChild(locationLabel);
    locationContainer.appendChild(this._location);

    contentContainer.classList.add(this._CSS.contentContainer);

    if (!this.readOnly) {
      let modal = document.createElement('div');
      this._input = document.createElement('input');
      let urlBtn = document.createElement('button');
      let closeBtn = document.createElement('button');
      BtnTooltip(urlBtn, EdjsTooltips.locationFooter.url);

      modal.classList.add(this._CSS.modal, this._CSS.editorBlockModal);
      this._input.classList.add(this._CSS.input, this._CSS.editorBlockModalInput, this._CSS.editorFont);
      urlBtn.classList.add(this._CSS.blockBtn, this._CSS.urlBtn);
      closeBtn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);

      closeBtn.innerHTML = CloseIcon;
      urlBtn.innerHTML = LinkIcon;
      this._input.placeholder = "Add link here"
      this._input.value = this._data.hotelUrl;

      const setUrl = () => {
        let httpPattern = /http:\/\/|https:\/\//;
        // eslint-disable-next-line no-useless-escape
        let urlPattern = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9:%@._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&\/=]*)/g;
        // eslint-disable-next-line no-useless-escape
        let emailPattern = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        // eslint-disable-next-line no-useless-escape
        let phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/g;
        // let slidePattern = /slide\s\d+/;
        let link = "";

        if (this._input.value.match(emailPattern)) {
          link = "mailto:" + this._input.value;
        }
        else if (this._input.value.match(urlPattern)) {
          if (!!(this._input.value.match(httpPattern))) {
            link = this._input.value;
          }
          else {
            link = "http://" + this._input.value;
          }
        }
        else if (this._input.value.match(phonePattern)) {
          link = "tel:" + this._input.value;
        }

        this._input.value = link;
        this._data.hotelUrl = link;
        this._hotelUrl.href = link;
      }

      this._input.addEventListener('keydown', (e) => {
        if (e.key === "Enter") {
          // Get the input value and log it to the console
          modal.classList.toggle(this._CSS.modalVisible);
          setUrl();
        }
      });

      closeBtn.addEventListener('click', () => {
        modal.classList.toggle(this._CSS.modalVisible);
        setUrl();

      });

      urlBtn.addEventListener('click', () => modal.classList.toggle(this._CSS.modalVisible))

      modal.appendChild(this._input);
      modal.appendChild(closeBtn);

      contentContainer.appendChild(modal);
      contentContainer.appendChild(urlBtn);
    }

    contentContainer.appendChild(locationContainer);
    contentContainer.appendChild(this._hotelUrl);
    this._container.appendChild(contentContainer);

    return this._container;
  }

  save() {
    let selectedLocation = this._location.options[this._location.selectedIndex]
    this._data.location = {
      value: selectedLocation.index,
      text: selectedLocation.text
    };
    this._data.hotelUrl = this._input.value;

    return this._data;
  }
}