// Dependencies
import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// App
import { apiEndpoints } from "../../config";
import { getOnlineNode } from '../../core/getNode';
import PostBody from '../../partials/postBody';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';
import CommentSection from '../../partials/comments/commentSection';
import ClientTheme from '../../core/clientTheme';
import PostImage from '../../partials/postImage';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Typography, Box, Link } from '@mui/material';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import MailIcon from '@mui/icons-material/EmailOutlined';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import PinIcon from '@mui/icons-material/PlaceOutlined';
import SinglesHeader from '../../partials/singlesHeader';

// Dummy data

export default function EventSingle(props) {
  const [isLoading, setIsLoading] = useState(true);
  // const [isPaginating, setIsPaginating] = useState(false);
  const [post, setPost] = useState(null);
  // const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  // const [comments, setComments] = useState([]);
  // const [pageSize, setPageSize] = useState(10);
  // const [currentPage, setCurrentPage] = useState(0);
  // const [total_pages, setTotal_Pages] = useState(0);
  // const [isReactionsLoading, setIsReactionsLoading] = useState(true);
  // const [reactions, setReactions] = useState(
  // {
  //   hasReacted: false,
  //   likes: 0,
  //   love: 0,
  //   suprised: 0,
  // },);

  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();
  const clientTheme = { ...ClientTheme() };


  useEffect(() => {
    loadNode()
  }, []);

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */

  const setData = (_data) => {
    setPost(_data);
    setIsLoading(false);
    setIsError(false);
    // getComments();
    // getReactions();
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  const loadNode = () => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
      // getComments(nid)
    } else {
      setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid) => {
    let path = `${apiEndpoints.events}/all/${nid}?_format=json&status=1&promote=1`;

    getOnlineNode(path, user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setData(response.data.rows[0]);
        } else {
          setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        // if (_error.response) {
        //   setError(
        //     true,
        //     _error.response.status,
        //     _error.response.statusText,
        //   );
        // }
        // else if (_error.request) {
        //   setError(true, 0, alertMessages.requestError.message);
        // } else {
        //   setError(true, 0, alertMessages.unkownError.message);
        // }
      });
  };

  const processTime = (time) => {
    if (time) {
      const milliseconds = parseInt(time.replace(/\n/g, '').trim()) * 1000;

      let date = new Date(milliseconds);
      let day = date.toLocaleString("en-GB", { dateStyle: "long", timeZone: "Europe/London" });
      let hour = date.toLocaleString("en-GB", { hour12: true, timeStyle: "short", timeZone: "Europe/London" });
      return { hour: hour, day: day };
    }
  }

  let start = processTime(post?.field_start_date);
  let end = processTime(post?.field_end_date);

  const renderEventDetails = () => {
    const iconStyle = {
      fontSize: 25,
      color: 'clientPrimary.main'
    }

    const img = post.field_featured_image;

    return (
      <Box
        className='mb-5'
      >
        <Row>
          <Col xs={4} className='d-none d-md-block'>
            <PostImage imgSrc={img} nid={post.nid} className="ratio ratio-1x1 event-single-img-container" />
          </Col>
          <Col xs={12} md={8} className='d-flex flex-column ps-4'>
            <Row className='d-flex flex-nowrap align-items-center justify-content-start mb-1 w-100'>
              <Col className='d-flex align-items-center' xs={1}>
                <TimeIcon sx={iconStyle} />
              </Col>
              <Col xs={11}>
                <Typography
                  className='d-flex'
                  owner="client"
                  variant="body1"
                  weight="light"
                  color="clientText.main"
                >
                  {start?.hour === end?.hour ? end?.hour : `${start?.hour} - ${end?.hour}`}
                </Typography>
              </Col>
            </Row>
            <Row className='d-flex flex-nowrap align-items-center justify-content-start my-1 w-100'>
              <Col className='d-flex align-items-center' xs={1}>
                <CalendarIcon sx={iconStyle} />
              </Col>
              <Col xs={11}>
                <Typography
                  className='d-flex'
                  owner="client"
                  variant="body1"
                  weight="light"
                  color="clientText.main"
                >
                  {start?.day === end?.day ? end?.day : `${start?.day} - ${end?.day}`}
                </Typography>
              </Col>
            </Row>
            {post.field_contact_number &&
              <Row className='d-flex flex-nowrap align-items-center justify-content-start my-1 w-100'>
                <Col className='d-flex align-items-center' xs={1}>
                  <PhoneIcon sx={iconStyle} />
                </Col>
                <Col xs={11}>
                  <Typography
                    className='d-flex'
                    owner="client"
                    variant="body1"
                    weight="light"
                    color="clientText.main"
                  >
                    <Link
                      href={`tel:${post.field_contact_number}`}
                      owner="client"
                      variant="body1"
                      weight="light"
                      lh="wide"
                      hoverbehavior={clientTheme.components.link.hoverbehavior}
                    >
                      {post.field_contact_number}
                    </Link>
                  </Typography>
                </Col>
              </Row>
            }
            {post.field_email &&
              <Row className='d-flex flex-nowrap align-items-center justify-content-start my-1 w-100'>
                <Col className='d-flex align-items-center' xs={1}>
                  <MailIcon sx={iconStyle} />
                </Col>
                <Col xs={11}>
                  <Typography
                    className='d-flex'
                    owner="client"
                    variant="body1"
                    weight="light"
                    color="clientText.main"
                  >
                    <Link
                      owner="client"
                      variant="body1"
                      weight="light"
                      lh="wide"
                      hoverbehavior={clientTheme.components.link.hoverbehavior}
                      href={`mailto: ${post.field_email}`}>
                      {post.field_email}
                    </Link>
                  </Typography>
                </Col>
              </Row>
            }
            {post.field_website &&
              <Row className='d-flex flex-nowrap align-items-center justify-content-start my-1 w-100'>
                <Col className='d-flex align-items-center' xs={1}>
                  <LinkIcon sx={iconStyle} />
                </Col>
                <Col xs={11}>
                  <Typography
                    className='d-flex'
                    owner="client"
                    variant="body1"
                    weight="light"
                    color="clientText.main"
                  >
                    <Link
                      owner="client"
                      variant="body1"
                      weight="light"
                      lh="wide"
                      hoverbehavior={clientTheme.components.link.hoverbehavior}
                      href={`${post.field_website}`}
                      target='_blank'
                    >
                      {post.field_website}
                    </Link>
                  </Typography>
                </Col>
              </Row>
            }
            {post.field_address &&
              <Row className='d-flex flex-nowrap align-items-center justify-content-start my-1 w-100'>
                <Col className='d-flex align-items-center' xs={1}>
                  <PinIcon sx={iconStyle} />
                </Col>
                <Col xs={11}>
                  <Typography
                    className='d-flex'
                    owner="client"
                    variant="body1"
                    weight="light"
                    color="clientText.main"
                  >
                    <Link
                      owner="client"
                      variant="body1"
                      weight="light"
                      lh="wide"
                      hoverbehavior={clientTheme.components.link.hoverbehavior}
                      href={`http://maps.google.com/?q=${post.field_address}`}
                      target='_blank'
                    >
                      {post.field_address}
                    </Link>
                  </Typography>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Box>
    )
  }


  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return (
        <Error
          status={errorStatus}
          message={errorMessage}
        />
      );
    } else {
      let category = 'Uncategorised';

      if (post.category_labels) {
        category = post.category_labels;
      }

      return (
        <main className="events single">
          <article className="article">
            <SinglesHeader
              route={"/events"}
              backBtnText={"Upcoming Events"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            {renderEventDetails()}
            <PostBody body={post.body} />
            <div className="article__body">
            </div>
            <CommentSection nid={post.nid} />
          </article>
        </main>
      );
    }
  }
}

