import React from 'react';
import { useNavigate } from "react-router-dom";


import { serverUrl } from '../../config';
import GridItem from '../../partials/gridItem';
import ListItem from '../../partials/listItem';
import FavouriteBtn from "../../partials/favouriteBtn";
import { useWindowSize } from "../../partials/windowSize"

import { Col } from 'react-bootstrap';
import { Button } from '@mui/material';
import OpenNewIcon from '@mui/icons-material/OpenInNewOutlined';

export default function DocumentItem(props) {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  let listItem = {
    id: props.item?.nid,
    title: props.item?.title,
    category_label: props.item?.category_labels,
    className: "document"
  }

  return (
    <>
      {props.gridView &&
        <GridItem
          xs={12}
          md={3}
          itemClass="document"
          title={props.item?.title}
          field_body={props.item?.summary ? props.item?.summary : props.item?.body}
          cardAction={true}
          cardActionCallBack={() => {
            // navigate(`/documents/${props.item?.nid}`)
            if (props.item?.field_document) {
              window.open(serverUrl + props.item?.field_document, '_blank')
            }
          }}
          cardActions={true}
          category_label={props.item?.category_labels}
        >
          <Col xs={"auto"} className='d-flex align-items-center'>
            <Button
              variant="screenItemBtn"
              mode="default"
              onClick={() => {
                if (props.item?.field_document) {
                  window.open(serverUrl + props.item?.field_document, '_blank')
                }
                // window.open(serverUrl + props.item?.uri, '_blank')
                // navigate(`/documents/${props.item?.nid}`)
              }}
            >
              <OpenNewIcon sx={{ fontSize: "0.9rem" }} />
            </Button>
          </Col>
          <FavouriteBtn
            id={props.item.nid}
            contentType={props.contentType}
            mode={"default"}
          />
        </GridItem>
      }
      {!props.gridView &&
        <ListItem
          active={props.activeDetail === props.item?.nid}
          owner="client"
          item={listItem}
          idEnabled={width < 576 ? false : true}
          categoryEnabled={width < 768 ? false : true}
          infoBtn
          handleInfo={() => props.setActiveDetails(props.item?.nid)}
          infoDisabled={!props.item?.body}
          openBtn
          openBtnDisabled={!props.item?.field_document}
          handleOpen={() => {
            if (props.item?.field_document) {
              window.open(serverUrl + props.item?.field_document, '_blank')
            }
            // navigate()
            // window.open(`/documents/${props.item?.nid}`, '_blank')
          }}
          accordInfo
          accordInfoBody={props.item?.body ? props.item?.body : ''}
          contentType={props.contentType}
        >
        </ListItem>
      }
    </>
  )
}