import { CapacityIcon } from '../../../icons/icons'

/**
 * Build styles
 */
require('./index.css').toString();

export default class HotelCapacity {

  static get toolbox() {
    return {
      icon: CapacityIcon,
      title: 'Hotel capacity',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
    };
  }

  static get DEFAULT_GUEST_ROOMS() {
    return '#'
  }

  static get DEFAULT_MEETING_ROOMS() {
    return '#'
  }

  static get DEFAULT_MAX_CAP() {
    return '#'
  }

  static get DEFAULT_EXHIBIT_SPACE() {
    return '#'
  }


  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-hotel-capacity-container',
      contentContainer: 'cdx-hotel-capacity-content-container',
      wrapper: 'cdx-hotel-capacity',
      input: 'cdx-hotel-capacity-input',
      guestRoom: 'hotel-guest-room',
      meetingRooms: 'hotel-meeting-rooms',
      maxCap: 'hotel-max-cap',
      exhibitSpace: 'hotel-exhibit-space',
      label: 'hotel-capacity-label'
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      guestRoom: data.guestRoom || '',
      meetingRooms: data.meetingRooms || '',
      maxCap: data.maxCap || '',
      exhibitSpace: data.exhibitSpace || '',
    };
    this._container = ''
    this._guestRoomPlaceHolder = config.guestRoomPlaceHolder ? config.guestRoomPlaceHolder : HotelCapacity.DEFAULT_GUEST_ROOMS;
    this._meetingRoomsPlaceHolder = config.meetingRoomsPlaceHolder ? config.meetingRoomsPlaceHolder : HotelCapacity.DEFAULT_MEETING_ROOMS;
    this._maxCapPlaceHolder = config.maxCapPlaceHolder ? config.maxCapPlaceHolder : HotelCapacity.DEFAULT_MAX_CAP;
    this._exhibitSpacePlaceHolder = config.exhibitSpacePlaceHolder ? config.exhibitSpacePlaceHolder : HotelCapacity.DEFAULT_EXHIBIT_SPACE;
    this._guestRooms = this.getGuestRooms();
    this._meetingRooms = this.getMeetingRooms();
    this._maxCap = this.getMaxCap();
    this._exhibitSpace = this.getExhibitSpace();
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  getGuestRooms() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._guestRoomPlaceHolder);
    tag.innerHTML = this._data.guestRoom || '';
    tag.classList.add(this._CSS.guestRoom, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._guestRooms);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getMeetingRooms() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._meetingRoomsPlaceHolder);
    tag.innerHTML = this._data.meetingRooms || '';
    tag.classList.add(this._CSS.meetingRooms, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._meetingRooms);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getMaxCap() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._maxCapPlaceHolder);
    tag.innerHTML = this._data.maxCap || '';
    tag.classList.add(this._CSS.maxCap, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._maxCap);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getExhibitSpace() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._exhibitSpacePlaceHolder);
    tag.innerHTML = this._data.exhibitSpace || '';
    tag.classList.add(this._CSS.exhibitSpace, this._CSS.input);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._exhibitSpace);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }


  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  render() {
    this._container = document.createElement('div');
    this._container.classList.add(this._CSS.container);
    let guestRoomsLabel = document.createElement('p');
    let meetingRoomsLabel = document.createElement('p');
    let maxCapLabel = document.createElement('p');
    let exhibitSpaceLabel = document.createElement('p');

    guestRoomsLabel.classList.add(this._CSS.label);
    meetingRoomsLabel.classList.add(this._CSS.label);
    maxCapLabel.classList.add(this._CSS.label);
    exhibitSpaceLabel.classList.add(this._CSS.label);

    guestRoomsLabel.innerHTML = 'GUEST<br>ROOMS';
    meetingRoomsLabel.innerHTML = 'MEETING<br>ROOMS';
    maxCapLabel.innerHTML = 'MAX<br>CAP';
    exhibitSpaceLabel.innerHTML = 'EXHIBITION<br>SPACE SQM';

    let contentContainer = document.createElement('div');
    contentContainer.classList.add(this._CSS.contentContainer);


    contentContainer.appendChild(this._guestRooms);
    contentContainer.appendChild(guestRoomsLabel);
    contentContainer.appendChild(this._meetingRooms);
    contentContainer.appendChild(meetingRoomsLabel);
    contentContainer.appendChild(this._maxCap);
    contentContainer.appendChild(maxCapLabel);
    contentContainer.appendChild(this._exhibitSpace);
    contentContainer.appendChild(exhibitSpaceLabel);
    this._container.appendChild(contentContainer);

    return this._container;
  }

  save() {
    this._data.guestRoom = this._guestRooms.innerHTML;
    this._data.meetingRooms = this._meetingRooms.innerHTML;
    this._data.maxCap = this._maxCap.innerHTML;
    this._data.exhibitSpace = this._exhibitSpace.innerHTML;

    return this._data;
  }
}